<template>
  <div class="addTheme">
    <div class="top">
      <h6>试吃活动-{{ themeTitle }}</h6>
      <div class="back" @click="cancel">返回上一级</div>
    </div>
    <div class="addTheme_main">
      <div class="writeoff_box">
        <span><i>*</i>关联主题活动：</span>
        <el-select
          v-model="addForm.typeId"
          class="dialog_input"
          placeholder="请选择"
        >
          <el-option
            v-for="item in parentList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>活动标题：</span>
        <el-input
          v-model="addForm.activityName"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span>副标题：</span>
        <el-input
          v-model="addForm.subtitle"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>活动时间：</span>
        <el-date-picker
          v-model="dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
        />
      </div>
      <div class="writeoff_box">
        <span><i>*</i>总可参与人数：</span>
        <el-input
          v-model="addForm.limitedQuotaTotal"
          type="text"
          placeholder="请输入"
          class="dialog_input"
          @keyup="numberInput2"
        ></el-input>
      </div>
      <div class="writeoff_box remark_box">
        <span><i>*</i>活动详情：</span>
        <QuillEditor
          class="myQuillEditor"
          ref="quillRef"
          theme="snow"
          v-model:content="addForm.description"
          :options="editorOption"
          contentType="html"
        />
      </div>
      <div class="writeoff_box remark_box">
        <span><i>*</i>报名成功提示语：</span>
        <QuillEditor
          class="myQuillEditor"
          ref="signupTipRef"
          theme="snow"
          v-model:content="addForm.signupTip"
          :options="editorOption"
          contentType="html"
        />
      </div>
      <div class="writeoff_box remark_box">
        <span><i>*</i>活动结束提示语：</span>
        <QuillEditor
          class="myQuillEditor"
          ref="endTipRef"
          theme="snow"
          v-model:content="addForm.endTip"
          :options="editorOption"
          contentType="html"
        />
      </div>
      <div class="writeoff_box remark_box">
        <span><i>*</i>客服联系二维码：</span>
        <el-upload
          class="upload-demo"
          drag
          :limit="1"
          :action="UploadUrl1()"
          :before-upload="beforeUploadFile1"
          :on-change="fileChange1"
          :on-exceed="exceedFile1"
          :on-remove="handleRemove1"
          :file-list="fileList1"
          :http-request="uploadFile1"
          :disabled="fileDisabled1"
          v-loading="loadingDialog1"
        >
          <img style="width: 100%" v-if="fileObj1.url" :src="fileObj1.url" />
          <el-icon v-if="!fileObj1.url" class="el-icon--upload">
            <I name="UploadFilled"></I>
          </el-icon>
          <div v-if="!fileObj1.url" class="el-upload__text">
            将文件拖拽此处，或<em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <div class="writeoff_box remark_box">
        <span><i>*</i>活动封面图：</span>
        <el-upload
          class="upload-demo"
          drag
          :limit="1"
          :action="UploadUrl()"
          :before-upload="beforeUploadFile"
          :on-change="fileChange"
          :on-exceed="exceedFile"
          :on-remove="handleRemove"
          :file-list="fileList"
          :http-request="uploadFile"
          :disabled="fileDisabled"
          v-loading="loadingDialog"
        >
          <img style="width: 100%" v-if="fileObj.url" :src="fileObj.url" />
          <el-icon v-if="!fileObj.url" class="el-icon--upload">
            <!-- <upload-filled /> -->
            <I name="UploadFilled"></I>
          </el-icon>
          <div v-if="!fileObj.url" class="el-upload__text">
            将文件拖拽此处，或<em>点击上传</em>
          </div>
          <!-- <template #tip>
            <div class="el-upload__tip">请上传850×676比例图片</div>
          </template> -->
        </el-upload>
      </div>
      <div class="writeoff_box remark_box">
        <span><i>*</i>活动流程环节（点击相应环节填写时间）：</span>
        <div style="width: 80%">
          <el-steps
            :active="stepsActive"
            finish-status="success"
            simple
            style="margin-top: 20px"
          >
            <el-step
              :title="item.stageName"
              v-for="(item, i) in stageModelList"
              :key="i"
              @click="clickSteps(i)"
            >
            </el-step>
          </el-steps>
          <template v-for="(item, i) in stageModelList" :key="i">
            <div v-if="i == stepsActive" style="margin-top: 30px">
              <span style="display: inline-block; width: 150px"
                >环节-{{ item.stageName }}：</span
              >
              <el-date-picker
                v-model="item.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="btns" v-if="themeTitle == '新建'">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit"> 创建活动 </el-button>
    </div>
    <div class="btns" v-if="themeTitle == '修改'">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit"> 修改活动 </el-button>
    </div>
  </div>
</template>

<script setup>
import { queryList } from '@/api/activityManagement/activityTypeManagement'
import { reactive, toRefs, onMounted, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { putObject } from '@/api/eventManagement'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const quillRef = ref()
const signupTipRef = ref()
const endTipRef = ref()
import { constantAuery } from '@/api/themeConfiguration'
import {
  activityAdd,
  activityEdit,
  activityEvery
} from '@/api/tasteConfiguration'
const OSS = require('ali-oss')
const initData = reactive({
  addForm: {
    typeId: '',
    activityName: '',
    subtitle: '',
    beginTime: '',
    endTime: '',
    description: '',
    picUrl: '',
    limitedQuotaTotal: '',
    signupTip: '',
    endTip: ''
  },
  parentList: [],
  dateTime: [],
  id: '',
  // 文件
  fileList: [],
  fileObj: {},
  fullscreenLoading: false,
  ossObj: '',
  loadingDialog: false,
  fileDisabled: false,
  // 二维码
  fileList1: [],
  fileObj1: {},
  fullscreenLoading1: false,
  ossObj1: '',
  loadingDialog1: false,
  fileDisabled1: false,
  themeTitle: '',
  editorOption: {
    placeholder: '请输入内容...'
  },
  stageModelList: [],
  stepsActive: 0
})
const {
  addForm,
  dateTime,
  id,
  fileList,
  fileObj,
  fullscreenLoading,
  ossObj,
  loadingDialog,
  fileDisabled,
  fileList1,
  fileObj1,
  fullscreenLoading1,
  ossObj1,
  loadingDialog1,
  fileDisabled1,
  stageModelList,
  parentList,
  themeTitle,
  editorOption,
  stepsActive
} = toRefs(initData)

onMounted(async () => {
  if (route.query.id) {
    id.value = route.query.id
  }
  document.getElementsByClassName('app-main')[0].scrollTo(0, 0)
  quillRef.value.getQuill().enable(false)
  signupTipRef.value.getQuill().enable(false)
  endTipRef.value.getQuill().enable(false)
  setTimeout(() => {
    quillRef.value.getQuill().enable(true)
    signupTipRef.value.getQuill().enable(true)
    endTipRef.value.getQuill().enable(true)
  }, 1000)
  getselectList()
  await getDict()
  if (route.query.name) {
    themeTitle.value = route.query.name
  }
})

// 活动阶段
const getDict = () => {
  constantAuery({
    className: 'TasteActivityStage'
  }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data
        .filter(item => item.code != -1 && item.code != 5)
        .map(item => {
          return {
            stageName: item.value,
            stageCode: item.code,
            time: []
          }
        })
      stageModelList.value = list
      if (id.value != '') {
        getData()
      }
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 总预约人数
const numberInput2 = () => {
  addForm.value.limitedQuotaTotal = addForm.value.limitedQuotaTotal.replace(
    /[^\d]/g,
    ''
  )
}

// 环节
const clickSteps = i => {
  const index = stageModelList.value.findIndex(
    item => item.time?.length == 0 || !item.time
  )
  if (index != -1 && index != i && index != stageModelList.value.length - 1) {
    stepsActive.value = index
    ElMessage.warning(`请填写环节-${stageModelList.value[index].stageName}时间`)
  } else {
    stepsActive.value = i
  }
}

// 获取主题活动列表
// const getselectList = () => {
//   selectList({ type: 2 }).then(({ data: res }) => {
//     if (res.code == 200) {
//       const list = res.data.map((item) => {
//         return {
//           label: item.title,
//           value: item.id,
//         };
//       });
//       parentList.value = list;
//     } else {
//       ElMessage.error(res.msg);
//     }
//   });
// };

// 活动类型
const getselectList = () => {
  queryList().then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.typeName,
          value: item.id
        }
      })
      parentList.value = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 返回上一级、取消
const cancel = () => {
  router.push('/tasteConfiguration')
}

// 创建活动
const submit = () => {
  if (addForm.value.typeId == '') {
    ElMessage.warning('请输入关联主题活动')
    return false
  }
  if (addForm.value.activityName == '') {
    ElMessage.warning('请输入活动标题')
    return false
  }
  if (dateTime.value.length == 0) {
    ElMessage.warning('请选择活动时间')
    return false
  }
  if (addForm.value.limitedQuotaTotal == '') {
    ElMessage.warning('请输入总可参与人数')
    return false
  }
  if (!regExp(quillRef.value.getText())) {
    ElMessage.warning('请输入活动详情')
    return false
  }
  if (!regExp(signupTipRef.value.getText())) {
    ElMessage.warning('请输入报名成功提示语')
    return false
  }
  if (!regExp(endTipRef.value.getText())) {
    ElMessage.warning('请输入活动结束提示语')
    return false
  }
  if (!fileObj1.value.url) {
    ElMessage.warning('请上传客服联系二维码')
    return false
  }
  if (!fileObj.value.url) {
    ElMessage.warning('请上传活动封面图')
    return false
  }
  const flag = stageModelList.value.every(
    item => item.time?.length > 0 && item.time
  )
  if (!flag) {
    ElMessage.warning('请选择活动流程环节时间')
    return false
  } else {
    stageModelList.value.map(item => {
      item.beginTime = item.time[0]
      item.endTime = item.time[1]
    })
  }
  let data = addForm.value
  data.stageModelList = stageModelList.value
  data.beginTime = dateTime.value[0]
  data.endTime = dateTime.value[1]
  data.picUrl = fileObj.value.url
  data.customerServicePic = fileObj1.value.url
  if (id.value == '') {
    activityAdd(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        setTimeout(() => {
          router.push('/tasteConfiguration')
        }, 1000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    data.id = id.value
    data.stageModelList.forEach(item => {
      if (!item.stageName) {
        item.stageName = item.integral
      }
    })
    activityEdit(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        setTimeout(() => {
          router.push('/tasteConfiguration')
        }, 1000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 富文本验证
const regExp = str => {
  const pattern_Ch = new RegExp('[\u4E00-\u9FA5]')
  const pattern_En = new RegExp('[A-Za-z]')
  const pattern_Num = new RegExp('[0-9]')
  const pattern_Sy = new RegExp(
    "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/\\?~！@#￥……&*（）——|{}【】‘；：”“'。，、？+-/]|[\\\\/]"
  )
  return (
    pattern_Ch.test(str) ||
    pattern_En.test(str) ||
    pattern_Sy.test(str) ||
    pattern_Num.test(str)
  )
}
const getData = () => {
  activityEvery({ id: id.value }).then(({ data: res }) => {
    if (res.code == 200) {
      addForm.value = res.data
      dateTime.value = [res.data.beginTime, res.data.endTime]
      fileObj.value.url = res.data.picUrl
      fileObj1.value.url = res.data.customerServicePic
      const stageModelLists = res.data.stageModelList
        .filter(item => item.stageCode != -1 && item.stageCode != 5)
        .map(item => {
          return {
            stageName: item.stageName,
            stageCode: item.stageCode,
            time: [item.beginTime, item.endTime]
          }
        })
      stepsActive.value = stageModelLists.length - 1
      stageModelList.value = stageModelLists
      quillRef.value.setHTML(res.data.description)
      signupTipRef.value.setHTML(res.data.signupTip)
      endTipRef.value.setHTML(res.data.endTip)
    } else {
      ElMessage.error(res.msg)
    }
  })
}

let client = null
// 文件超出个数限制时的钩子
const exceedFile = (files, fileList) => {
  ElMessage.warning(
    `只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`
  )
}

// 文件状态改变时的钩子
const fileChange = file => {
  fileList.value.push(file.raw)
}

const asyncImgChecked = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file) // 必须用file.raw
    reader.onload = () => {
      // 让页面中的img标签的src指向读取的路径
      let img = new Image()
      img.src = reader.result
      img.onload = () => {
        if (img.width != 850 || img.height != 676) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
    }
  })
}

// 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
const beforeUploadFile = async file => {
  let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
  // let size = file.size / 1024 / 1024;
  if (extension != 'png' && extension != 'jpg' && extension != 'jpeg') {
    ElMessage.warning('只能上传后缀是png、jpg、jpeg的文件')
    return false
  }
  // let res = await asyncImgChecked(file)
  // if (!res) {
  //   ElMessage.warning('请上传850*676比例图片')
  //   return false
  // }
}

const handleRemove = () => {
  fileList.value = []
  fileObj.value.url = ''
}

const UploadUrl = () => {
  // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
  return ''
}

// 上传
const uploadFile = option => {
  // 获取文件的后缀名
  fullscreenLoading.value = true
  putObject({ fileName: option.file.name }).then(({ data: res }) => {
    if (res.code == 200) {
      ossObj.value = res.data
      client = new OSS({
        region: res.data.regionId,
        secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        bucket: res.data.bucketName,
        cname: true,
        endpoint: `https://${res.data.cdnDomain}`
      })
      put(`${res.data.dirPath + res.data.fileName}`, option.file).then(
        ({ res: data }) => {
          fullscreenLoading.value = false
          if (data.status == 200) {
            fileObj.value.url = data.requestUrls[0]
          } else {
            ElMessage.warning('上传失败')
          }
        }
      )
    }
  })
}

/**
 * @param {string} ObjName OSS的储存路径和文件名字
 * @param {string} fileUrl 本地文件
 * @retruns Promise
 */

const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl)
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
    return result
  } catch (e) {
    console.log(e)
  }
}

// 文件超出个数限制时的钩子
const exceedFile1 = (files, fileList) => {
  ElMessage.warning(
    `只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`
  )
}

// 文件状态改变时的钩子
const fileChange1 = file => {
  fileList1.value.push(file.raw)
}

const asyncImgChecked1 = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file) // 必须用file.raw
    reader.onload = () => {
      // 让页面中的img标签的src指向读取的路径
      let img = new Image()
      img.src = reader.result
      img.onload = () => {
        if (img.width != 850 || img.height != 676) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
    }
  })
}

// 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
const beforeUploadFile1 = async file => {
  let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
  // let size = file.size / 1024 / 1024;
  if (extension != 'png' && extension != 'jpg' && extension != 'jpeg') {
    ElMessage.warning('只能上传后缀是png、jpg、jpeg的文件')
    return false
  }
  // let res = await asyncImgChecked(file)
  // if (!res) {
  //   ElMessage.warning('请上传850*676比例图片')
  //   return false
  // }
}

const handleRemove1 = () => {
  fileList1.value = []
  fileObj1.value.url = ''
}

const UploadUrl1 = () => {
  // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
  return ''
}

// 上传
const uploadFile1 = option => {
  // 获取文件的后缀名
  fullscreenLoading1.value = true
  putObject({ fileName: option.file.name }).then(({ data: res }) => {
    if (res.code == 200) {
      ossObj1.value = res.data
      client = new OSS({
        region: res.data.regionId,
        secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        bucket: res.data.bucketName,
        cname: true,
        endpoint: `https://${res.data.cdnDomain}`
      })
      put(`${res.data.dirPath + res.data.fileName}`, option.file).then(
        ({ res: data }) => {
          fullscreenLoading1.value = false
          if (data.status == 200) {
            fileObj1.value.url = data.requestUrls[0]
          } else {
            ElMessage.warning('上传失败')
          }
        }
      )
    }
  })
}
</script>
<style lang="scss" scoped>
.addTheme {
  .top {
    display: flex;
    justify-content: space-between;
    margin: 15px 30px;
    h6 {
      font-size: 20px;
      font-weight: normal;
    }
    .back {
      font-size: 14px;
      color: #409eff;
      cursor: pointer;
    }
  }
  .writeoff_box {
    // display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
      position: relative;
      font-size: 14px;
      i {
        color: red;
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
  .remark_box {
    display: flex;
    align-items: flex-start;
  }
  .block {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
  .addTheme_main {
    margin-left: 50px;
  }
  .addhj {
    color: #409eff;
    cursor: pointer;
  }
  .jf_box {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    .dialog_input {
      width: 200px;
      margin-right: 20px;
    }
    .cont {
      display: flex;
      align-items: center;
      & > span {
        width: auto;
      }
      .delete {
        color: red;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  .btns {
    text-align: center;
    margin: 30px 0;
  }
  .icon_box {
    display: flex;
    .tipIcon {
      cursor: pointer;
      margin-left: 15px;
      font-size: 22px !important;
    }
  }
}
</style>
<style lang="scss">
.addTheme {
  .writeoff_box {
    .el-date-editor {
      width: 400px;
    }
    .dialog_input {
      width: 400px;
    }
  }
  .upload-demo {
    width: 400px;
  }
  .ql-toolbar {
    display: none;
  }
  .myQuillEditor {
    width: 400px !important;
    height: 200px !important;
    border-top: 1px solid #dcdfe6 !important;
    border-radius: 10px;
  }
  .ql-editor.ql-blank::before {
    color: #999;
    font-style: normal;
  }
  .el-steps {
    width: 100%;
    margin-top: 0 !important;
    margin-left: 10px;
  }
}
</style>
